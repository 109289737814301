import { createRouter, createWebHistory } from 'vue-router'

const routes = [
    {
        name: 'main',
        path: '/',
        component: () => import('../views/HomePage.vue'),
        meta: {
            title: 'Главная',
        }
    },
    {
        name: 'meetings',
        path: '/meetings',
        component: () => import('../views/MeetingsPage.vue'),
        meta: {
            title: 'Заседания',
            requiresAuth: true,
            roles: ['member']
        }
    },
    {
        name: 'voting',
        path: '/voting/:id',
        component: () => import('../views/VotingPage.vue'),
        meta: {
            title: 'Голосование',
            requiresAuth: true,
            show: false,
            roles: ['member', 'secretary']
        }
    },
    {
        name: 'NotFound',
        path: '/:pathMatch(.*)*',
        component: () => import('../views/NotFound.vue'),
        meta: {
            title: 'Страница не найдена',
            show: false
        }
    },

    {
        name: 'meetingsManager',
        path: '/manager/meetings',
        component: () => import('../views/Manager/MeetingsPage.vue'),
        meta: {
            title: 'Работа с заседаниями',
            requiresAuth: true,
            roles: ['secretary', 'manager']
        }
    },
    {
        name: 'viewMeetings',
        path: '/manager/viewMeetings',
        component: () => import('../views/MeetingsPage.vue'),
        meta: {
            title: 'Просмотр заседаний',
            requiresAuth: true,
            roles: ['secretary']
        }
    },
    {
        name: 'createMeeting',
        path: '/manager/meeting/:id',
        component: () => import('../views/Manager/CreateMeetingPage.vue'),
        meta: {
            title: 'Заседание',
            requiresAuth: true,
            show: false,
            roles: ['secretary', 'manager']
        }
    },
    {
        name: 'enterResults',
        path: '/manager/enterResults/:id',
        component: () => import('../views/Manager/EnterResults.vue'),
        meta: {
            title: 'Ввод результатов',
            requiresAuth: true,
            show: false,
            roles: ['secretary', 'manager']
        }
    },

    {
        name: 'accountInfo',
        path: '/account/info',
        component: () => import('../views/Account/AccountInfo.vue'),
        meta: {
            title: 'Информация о пользователе',
            requiresAuth: true,
            show: false,
            roles: ['member', 'secretary', 'manager']
        }
    }
]

const router = createRouter({
    linkExactActiveClass: 'nav-tem-active',
    history: createWebHistory(),
    mode: 'history',
    routes
})

function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms))
}

export default {
    install(app) {
        router.install(app)

        var keycloak = app.config.globalProperties.$keycloak

        router.beforeEach(async (to, from, next) => {
            if (to.matched.some(record => record.meta.requiresAuth)) {
                // waiting for keycloack to respond
                while (keycloak.createLoginUrl === null) await sleep(100)
                const roleExists = keycloak.tokenParsed?.realm_access.roles.filter(r => to.meta.roles?.includes(r)).length > 0

                if (keycloak.authenticated && roleExists) {
                    next()
                } else {
                    //const loginUrl = keycloak.createLoginUrl()
                    //window.location.replace(loginUrl)
                    next('/')
                }
            } else {
                next()
            }
        })
    }
}