import keycloak from '@dsb-norge/vue-keycloak-js'

export default {
    install(app) {
        keycloak.install(app, {
            init: {
                // Use 'login-required' to always require authentication
                // If using 'login-required', there is no need for the router guards in router.js
                // or check-sso
                onLoad: 'check-sso',
                silentCheckSsoRedirectUri: window.location.origin + '/silent-check-sso.html',
                useNonce: false
            },
            config: {
                url: process.env.VUE_APP_KEYCLOAK_URL,
                clientId: 'bod-ui-client',
                realm: 'bod',
            },
            onReady() {
            },
            onAuthLogout() {
                console.log('keycloak.onAuthLogout')
                window.location.replace(window.location.origin)
            }
        })
    }
}

/************
 * ������ ��� � @dsb-norge/vue-keycloak-js/node-mojules/dist
 * � ������ keycloak.js � keycloak.mjs
 * � ��������� ������ keycloak �������� ��� ����� changed
 * 
 * if (!(event.data == 'unchanged' || event.data == 'changed' || event.data == 'error')) {
 *           return;
 *       }
 * 
 * ������� ��� ������ unchanged �� changed
 *       if (event.data != 'changed') {
 *           kc.clearToken();
 *       }
 */