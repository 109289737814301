<template>
  <navigation-bar></navigation-bar>
  <content-space></content-space>
  <bottom-bar></bottom-bar>
</template>

<script>
    import NavigationBar from '@/components/layout/NavigationBar'
    import ContentSpace from '@/components/layout/ContentSpace'
    import BottomBar from '@/components/layout/BottomBar'

    export default {
        name: 'App',
        components: {
            NavigationBar,
            ContentSpace,
            BottomBar
        }
    }
</script>