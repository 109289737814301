import { createApp } from 'vue'
import App from '@/App.vue'
import fontAwesomeIcon from '@/modules/bootstrap'
import logger from '@/modules/logger'
import router from '@/modules/router'
import keycloak from '@/modules/keycloak'
import axios from '@/modules/axios'
import helpers from '@/helpers'
import ToastPlugin from 'vue-toast-notification'
import 'vue-toast-notification/dist/theme-bootstrap.css'

createApp(App)
    .component('fa-icon', fontAwesomeIcon)
    .use(logger)
    .use(keycloak)
    .use(router)
    .use(axios)
    .use(helpers)
    .use(ToastPlugin)
    .mount('#app')