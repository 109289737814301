import moment from 'moment'

const format = (d) => {
    //return moment(String(d)).format('DD.MM.YYYY hh:mm')
    return moment(d).format('DD.MM.YYYY HH:mm')
}

const formatShort = (d) => {
    //return moment(String(d)).format('DD.MM.YYYY')
    return moment(d).format('DD.MM.YYYY')
}

const isBetween = (a, b) => {
    return moment().isBetween(a, b)
}

const secondsFromNow = (d, sec) => {
    const a = moment(d).add(sec, 'seconds')
    var b = moment()
    console.log(a)
    console.log(b)
    return a.diff(b, 'seconds')
}

export {
    format,
    formatShort,
    isBetween,
    secondsFromNow
}