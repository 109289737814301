<template>
    <nav class="navbar navbar-expand-sm navbar-toggleable-sm navbar-dark bg-main border-bottom box-shadow">
        <div class="container-fluid">
            <a class="navbar-brand" href="/">СД</a>
            <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target=".navbar-collapse" aria-controls="navbarSupportedContent"
                    aria-expanded="false" aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
            </button>
            <div class="navbar-collapse collapse d-sm-inline-flex justify-content-between">
                <ul class="navbar-nav flex-grow-1">
                    <li class="nav-item" v-for="route in routes" :key="route.name">
                        <router-link class="nav-link" :to="route">{{ route.meta.title }}</router-link>
                    </li>
                </ul>
                <ul class="navbar-nav">
                    <li v-if="authenticated" class="nav-item">
                        <router-link class="nav-link" to="/account/info" title="Информация о пользователе"><fa-icon icon="fa-user"></fa-icon> {{ username }}</router-link>
                    </li>
                    <li v-if="authenticated" class="nav-item">
                        <a class="nav-link btn btn-link" href="signOut" v-on:click.prevent="signOut"><fa-icon icon="fa-right-from-bracket"></fa-icon> Выйти</a>
                    </li>
                    <li v-else class="nav-item">
                        <a class="nav-link text-dark" href="signIn" v-on:click.prevent="signIn"><fa-icon icon="fa-right-to-bracket"></fa-icon> Войти</a>
                    </li>
                </ul>
            </div>
        </div>
    </nav>
</template>

<script>
    export default {
        computed: {
            authenticated() {
                return this.$keycloak.authenticated
            },
            username() {
                return this.$keycloak.fullName
            },
            routes() {
                return this.$router.options
                    .routes.filter(route =>
                        (route.meta.requiresAuth == null || this.authenticated)
                        && (route.meta.show == null || route.meta.show)
                        && (route.meta.roles == null || this.$keycloak.tokenParsed?.realm_access.roles.filter(r => route.meta.roles.includes(r)).length > 0)
                    )
            }
        },
        methods: {
            signIn() {
                this.$keycloak.login()
            },
            signOut() {
                this.$keycloak.logoutFn()
            }
        }
    }
</script>