import logger from 'vuejs3-logger'

const options = {
    isEnabled: true,
    logLevel: process.env.NODE_ENV === 'production' ? 'error' : 'debug',
    stringifyArguments: false,
    showLogLevel: true,
    showMethodName: true,
    separator: '|',
    showConsoleColors: true
}

export default {
    install(app) {
        logger.install(app, options)
    }
}