import axios from 'axios'

export default {
    install(app) {
        var keycloak = app.config.globalProperties.$keycloak

        axios.defaults.timeout = 5 * 60 * 1000
        axios.interceptors.request.use(config => {
            if (keycloak.authenticated) {
                config.headers.Authorization = `Bearer ${keycloak.token}`
            }
            return config
        }, error => {
            return Promise.reject(error)
        })
    }
}